var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","background":"var(--v-background_normal-base)","box-sizing":"border-box"},style:(_vm.$vuetify.breakpoint.mdAndUp ? 'margin-top: 60px' : 'margin-top: 52px')},[_c('div',{staticStyle:{"z-index":"0","position":"absolute","width":"100vw"}},[_c('v-img',{attrs:{"src":require('@/assets/images/MyPage/MyPageBackground.jpg'),"max-height":"433px","min-height":"300px","gradient":"to top, rgba(0,0,0,0.6), rgba(19,42,86,0.6)"}})],1),_c('v-container',{staticClass:"d-flex",staticStyle:{"max-width":"1380px !important"},attrs:{"fluidd":"","pa-0":""}},[_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'mx-10' : 'mx-4',staticStyle:{"padding-bottom":"60px"},style:(_vm.$vuetify.breakpoint.lgAndUp
          ? 'padding-top: 100px'
          : _vm.$vuetify.breakpoint.smAndUp
          ? 'padding-top: 80px'
          : 'padding-top: 60px'),attrs:{"color":"transparent","width":"100%","flat":""}},[_c('div',{staticClass:"d-flex-column"},[_c('div',{staticClass:"font-weight-bold white_light_global--text",class:_vm.$vuetify.breakpoint.lgAndUp ? 'h1' : 'h2'},[_vm._v(" 쉽고 편한 3D프린팅 ")]),_c('div',{staticClass:"font-weight-bold white_light_global--text mt-5",class:_vm.$vuetify.breakpoint.lgAndUp ? 'title-1 ' : 'subtitle-1',staticStyle:{"max-width":"480px"}},[_vm._v(" 전국 각지 메이커스페이스 네트워크 구축을 통해 언제 어디서나 일관된 3D프린팅 서비스를 제공합니다. ")])]),_c('div',{style:(_vm.$vuetify.breakpoint.lgAndUp
            ? 'height: 80px'
            : _vm.$vuetify.breakpoint.smAndUp
            ? 'height: 60px'
            : 'height: 40px')}),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticStyle:{"z-index":"1","position":"relative","width":"100%","display":"flex","gap":"36px"}},[_c('form-section',{attrs:{"step":_vm.step,"orderData":_vm.orderData},on:{"update:step":function($event){_vm.step=$event},"update:orderData":function($event){_vm.orderData=$event},"update:order-data":function($event){_vm.orderData=$event}}}),(_vm.$vuetify.breakpoint.lgAndUp)?_c('sidebar',{attrs:{"step":_vm.step}}):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }