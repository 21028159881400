<template>
  <v-card
    width="100%"
    class="d-flex flex-column"
    :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : 'pa-6'"
    style="gap: 20px; border-radius: 16px"
  >
    <v-card-title
      class="pa-0 font-weight-bold"
      :class="$vuetify.breakpoint.smAndDown ? 'title-2' : 'title-1'"
    >
      {{ step }}
    </v-card-title>

    <customer-info v-if="step == '주문자 정보'" :orderData="orderData" />
    <order-basic-info v-if="step == '기본 정보'" :orderData="orderData" />
    <sketch-upload v-if="step == '스케치 업로드'" :orderData="orderData" />
    <file-upload v-if="step == '파일 업로드'" :orderData="orderData" />
    <additional-info v-if="step == '추가 정보'" :orderData="orderData" />

    <v-card-actions
      v-if="step == '주문자 정보'"
      :style="$vuetify.breakpoint.smAndDown ? 'height: 80px' : 'height: 100px'"
      class="align-end"
    >
      <v-btn
        class="px-4"
        color="background_normal"
        height="40px"
        elevation="0"
        @click="$router.push('/page')"
      >
        홈으로
      </v-btn>
      <v-spacer />
      <v-btn
        class="primary pl-4 pr-3"
        elevation="0"
        :loading="btnLoading"
        height="40px"
        @click="changeStep('기본 정보', step)"
      >
        <span> 다음 </span> <v-icon size="18"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions
      v-if="step == '기본 정보'"
      :style="$vuetify.breakpoint.smAndDown ? 'height: 80px' : 'height: 100px'"
      class="align-end"
    >
      <v-btn
        class="px-4"
        :loading="btnLoading"
        color="error"
        height="40px"
        elevation="0"
        @click="deleteOrder(orderData)"
      >
        삭제
      </v-btn>
      <v-spacer />
      <v-btn
        class="primary pl-4 pr-3"
        :loading="btnLoading"
        height="40px"
        elevation="0"
        @click="
          orderData.workspace == '모델링'
            ? changeStep('스케치 업로드', step)
            : changeStep('파일 업로드', step)
        "
      >
        <span> 다음 </span> <v-icon size="18"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions
      v-if="step == '파일 업로드'"
      :style="$vuetify.breakpoint.smAndDown ? 'height: 80px' : 'height: 100px'"
      class="align-end"
    >
      <v-btn
        class="px-4"
        height="40px"
        elevation="0"
        color="background_normal"
        @click="changeStep('기본 정보', step)"
      >
        이전
      </v-btn>
      <v-spacer />
      <v-btn
        class="primary pl-4 pr-3"
        :loading="btnLoading"
        height="40px"
        elevation="0"
        @click="changeStep('추가 정보', step)"
      >
        <span> 다음 </span> <v-icon size="18"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions
      v-if="step == '스케치 업로드'"
      :style="$vuetify.breakpoint.smAndDown ? 'height: 80px' : 'height: 100px'"
      class="align-end"
    >
      <v-btn
        class="px-4"
        height="40px"
        elevation="0"
        color="background_normal"
        @click="changeStep('기본 정보', step)"
      >
        이전
      </v-btn>
      <v-spacer />
      <v-btn
        class="primary px-4"
        :loading="btnLoading"
        height="40px"
        elevation="0"
        @click="finishOrder"
      >
        <span> 업로드 </span>
      </v-btn>
    </v-card-actions>

    <v-card-actions
      v-if="step == '추가 정보'"
      :style="$vuetify.breakpoint.smAndDown ? 'height: 80px' : 'height: 100px'"
      class="align-end"
    >
      <v-btn
        class="px-4"
        height="40px"
        elevation="0"
        color="background_normal"
        @click="
          orderData.workspace == '모델링'
            ? $emit('update:step', '스케치 업로드')
            : $emit('update:step', '파일 업로드')
        "
      >
        이전
      </v-btn>
      <v-spacer />
      <v-btn
        class="primary px-4"
        :loading="btnLoading"
        height="40px"
        elevation="0"
        @click="finishOrder"
      >
        <span> 업로드 </span>
      </v-btn>
    </v-card-actions>

    <WarningDialog
      :msg="alert"
      :visible.sync="alert.dialog"
      @confirm="alert.onConfirm"
      @cancel="alert.onCancel"
    />
  </v-card>
</template>

<script>
import _ from "lodash";
import CustomerInfo from "./customerInfo.vue";
import OrderBasicInfo from "./OrderBasicInfo.vue";
import SketchUpload from "./SketchUpload.vue";
import FileUpload from "./FileUpload.vue";
import AdditionalInfo from "./AdditionalInfo.vue";
import firestorageUtils from "@/utils/firestorage";

import ORDER from "@/API/order.js";

export default {
  props: { step: String, orderData: Object },
  components: {
    CustomerInfo,
    OrderBasicInfo,
    SketchUpload,
    FileUpload,
    AdditionalInfo,
  },
  data() {
    return {
      btnLoading: false,
      alert: {
        icon: "alert-triangle",
        dialog: false,
        msg: "",
        confirmMsg: "",
        cancelMsg: "",
        onConfirm: () => {},
        onCancel: () => {},
      },
    };
  },
  async mounted() {
    if (this.$router.currentRoute.query.id) {
      this.changeStep("기본 정보", "마이페이지");
    }
  },
  methods: {
    async changeStep(to, from) {
      if (to === "기본 정보" && from === "마이페이지") {
        try {
          this.btnLoading = true;
          const user = this.$store.getters["auth/user/GET_USER"];
          if (user) {
            ORDER.searchOrder(
              {
                field: "id",
                value: this.$router.currentRoute.query.id,
                limit: 1,
              },
              (orders) => {
                if (orders?.length) this.loadOrder(orders[0]);
              }
            );
          }
        } catch (error) {
          console.error("Error handling orders:", error);
          this.$toasted.global.error("주문서 처리 중 오류가 발생했습니다.");
        } finally {
          this.btnLoading = false;
        }
      }
      if (to === "기본 정보" && from === "주문자 정보") {
        try {
          this.btnLoading = true;
          const user = this.$store.getters["auth/user/GET_USER"];
          if (!user) {
            this.$toasted.global.notice(
              "비회원으로 진행 시 최종 접수는 불가합니다"
            );
            Object.assign(this.orderData, {
              uid: "",
              displayName: "",
              phoneNumber: "",
            });
            this.$emit("update:step", to);
            return (this.btnLoading = false);
          } else {
            if (!this.orderData.uid)
              return this.$toasted.global.error(
                "회원 정보가 로드되지 않았습니다. 다시 시도해주세요"
              );
          }

          ORDER.searchOrder(
            {
              field: "phoneNumber",
              value: this.orderData.phoneNumber,
              limit: 1,
            },
            async (orders) => {
              const pendingOrders = orders?.filter((order) =>
                ["Draft", "Pending", "Checking", "Hold"].includes(order.status)
              );

              if (pendingOrders?.length) {
                this.alert = {
                  dialog: true,
                  msg: "해당 연락처로 작성 중인 주문서가 존재합니다.",
                  onConfirm: () => this.loadOrder(pendingOrders[0]),
                  confirmMsg: "불러오기",
                  onCancel: () => this.deleteOrder(pendingOrders[0]),
                  cancelMsg: "삭제",
                };
              } else {
                const newOrderData = await ORDER.createOrder(this.orderData);
                this.$toasted.global.success("주문서 생성 완료");
                this.$emit("update:orderData", newOrderData);
                this.$emit("update:step", to);
              }
              this.btnLoading = false;
            }
          );
        } catch (error) {
          console.error("Error handling orders:", error);
          this.$toasted.global.error("주문서 처리 중 오류가 발생했습니다.");
          this.btnLoading = false;
        }
      }
      if (
        (to === "스케치 업로드" || to === "파일 업로드") &&
        from === "기본 정보"
      ) {
        if (this.orderData.purpose === "") {
          return this.$toasted.global.error("사용 목적을 입력해 주세요");
        }
        if (this.orderData.uid) {
          await this.saveOrder();
        }
        this.$emit("update:step", to);
      }
      if (
        to === "기본 정보" &&
        (from === "스케치 업로드" || from === "파일 업로드")
      ) {
        this.orderData.file.forEach((v) => delete v.delete);
        this.orderData.modelingFile.forEach((v) => delete v.delete);
        this.$emit("update:step", to);
      }
      if (to == "추가 정보" && from == "파일 업로드") {
        if (this.orderData.id) {
          this.btnLoading = true;
          const deletePromises = this.orderData.file
            .filter((file) => file.delete)
            .map(async (file) => {
              try {
                if (
                  file.previewImg &&
                  file.previewImg.includes("firebasestorage.googleapis.com")
                ) {
                  await firestorageUtils.deleteFile(file.previewImg);
                }
                if (
                  file.url &&
                  file.url.includes("firebasestorage.googleapis.com")
                ) {
                  await firestorageUtils.deleteFile(file.url);
                }
                console.log(
                  `Files associated with ${file.name} deleted successfully.`
                );
              } catch (error) {
                this.$toasted.global.error(`파일 삭제 실패`);
                console.error(
                  `Failed to delete file associated with ${file.name}:`,
                  error
                );
                this.btnLoading = false;
              }
            });
          await Promise.all(deletePromises);
          this.orderData.file = this.orderData.file.filter(
            (file) => !file.delete
          );
          const uploadPromises = this.orderData.file.map(
            async (file, index) => {
              if (
                typeof file.url === "string" &&
                file.url.startsWith("blob:")
              ) {
                try {
                  const blob = await (await fetch(file.url)).blob();
                  const result = await firestorageUtils.uploadFile({
                    file: blob,
                    path: `Orders/${this.orderData.id}/printFile/${file.name}`,
                  });
                  file.url = result.downloadURL;
                  if (file.previewImg) {
                    const blob1 = await (await fetch(file.previewImg)).blob();
                    const result1 = await firestorageUtils.uploadFile({
                      file: blob1,
                      path: `Orders/${
                        this.orderData.id
                      }/printFile/${file.name.replace(
                        /\.[^/.]+$/,
                        ""
                      )}_Img.png`,
                    });
                    file.previewImg = result1.downloadURL;
                  }
                  this.$toasted.global.notice(
                    `파일 ${index + 1}/${
                      this.orderData.file.length
                    } 업로드 성공`
                  );
                } catch (error) {
                  this.$toasted.global.error(`파일 업로드 실패`);
                  console.error("File upload failed: ", error);
                  this.btnLoading = false;
                }
              }
            }
          );
          await Promise.all(uploadPromises);
          await this.saveOrder();
        }
        this.$emit("update:step", to);
      }
    },
    loadOrder(order) {
      // selected 값을 추가
      if (order.file.length > 0) {
        order.file.forEach((v) => {
          v.selected = false;
          v.option.forEach((o) => (o.selected = false));
          if (v.option.length > 0) {
            v.option[0].selected = true;
          }
        });
        order.file[0].selected = true;
      }
      if (order.modelingFile.length > 0) {
        order.modelingFile.forEach((v) => {
          v.selected = false;
        });
        order.modelingFile[0].selected = true;
      }
      this.$emit("update:orderData", order);
      this.$emit("update:step", "기본 정보");
      this.btnLoading = false;
    },
    async saveOrder() {
      this.btnLoading = true;
      try {
        const orderDataCopy = _.cloneDeep(this.orderData);
        orderDataCopy.file.forEach((file) => {
          delete file.selected;
          delete file.delete;
          file.option.forEach((option) => delete option.selected);
        });
        orderDataCopy.modelingFile.forEach((file) => {
          delete file.selected;
          delete file.delete;
        });
        orderDataCopy.printingPrice = orderDataCopy.file.reduce((total, f) => {
          if (f.print) {
            // file.print === true인 경우만 포함
            return (
              total +
              f.option.reduce((optTotal, o) => {
                return optTotal + o.price * o.totalQty;
              }, 0)
            );
          }
          return total; // 조건에 맞지 않으면 total 유지
        }, 0);
        orderDataCopy.totalPrice =
          (Number(orderDataCopy.modelingPrice) || 0) +
          (Number(orderDataCopy.printingPrice) || 0) +
          (Number(orderDataCopy.shippingPrice) || 0) +
          (Number(orderDataCopy.discountPrice) || 0);
        const response = await this.$axios.put(
          `/orders/updateOrder/${this.orderData.id}`,
          {
            form: orderDataCopy,
          }
        );
        if (response.status === 200) {
          this.$toasted.global.success("임시 저장 완료");
        }
      } catch (error) {
        console.error("Error updating order:", error);
        this.$toasted.global.error(
          "오류가 발생했습니다. 새로고침 후 시도해주세요."
        );
      } finally {
        this.btnLoading = false;
      }
    },
    async deleteOrder(order) {
      this.btnLoading = true;
      try {
        await ORDER.deleteOrder(order);

        if (this.step == "주문자 정보") {
          const createResponse = await this.$axios.put(
            "orders/newOrder",
            this.orderData
          );
          this.$emit("update:orderData", createResponse.data);
          this.$toasted.global.success("신규 주문서 생성 완료");
          this.$emit("update:step", "기본 정보");
        }
        if (this.step == "기본 정보") {
          this.$toasted.global.success("주문서가 성공적으로 삭제되었습니다.");
          window.location.reload();
        }
      } catch (error) {
        console.error("Error deleting order:", error);
      } finally {
        this.btnLoading = false;
      }
    },
    async finishOrder() {
      this.btnLoading = true;
      if (this.orderData.uid) {
        if (this.orderData.modelingFile) {
          const deletePromises = this.orderData.modelingFile
            .filter((file) => file.delete)
            .map(async (file) => {
              try {
                if (
                  file.url &&
                  file.url.includes("firebasestorage.googleapis.com")
                ) {
                  await firestorageUtils.deleteFile(file.url);
                }
                console.log(
                  `Files associated with ${file.name} deleted successfully.`
                );
              } catch (error) {
                this.$toasted.global.error(`파일 삭제 실패`);
                console.error(
                  `Failed to delete file associated with ${file.name}:`,
                  error
                );
              }
            });
          await Promise.all(deletePromises);
          this.orderData.modelingFile = this.orderData.modelingFile.filter(
            (file) => !file.delete
          );
          const uploadPromises = this.orderData.modelingFile.map(
            async (file, index) => {
              if (
                typeof file.url === "string" &&
                file.url.startsWith("blob:")
              ) {
                try {
                  const blob = await (await fetch(file.url)).blob();
                  const result = await firestorageUtils.uploadFile({
                    file: blob,
                    path: `Orders/${this.orderData.id}/modelingFile/${file.name}`,
                  });
                  file.url = result.downloadURL;
                  this.$toasted.global.notice(
                    `파일 ${index + 1}/${
                      this.orderData.file.length
                    } 업로드 성공`
                  );
                } catch (error) {
                  this.$toasted.global.error(`파일 업로드 실패`);
                  console.error("File upload failed: ", error);
                }
              }
            }
          );
          await Promise.all(uploadPromises);
        }
        if (this.orderData.file && !this.orderData.shippingMethod) {
          this.btnLoading = false;
          return this.$toasted.global.error("배송 방법을 선택해주세요!");
        }
        if (this.orderData.status == "Hold") {
          this.orderData.status = "Checking";
        } else {
          this.orderData.status = "Pending";
        }

        if (!this.orderData.channelTalk) {
          // 임시로 사용하는 채널톡
          const s = await this.$axios.post("notice/orderCreated", {
            user: window.channelUser,
            order: this.orderData,
          });
          this.orderData.channelTalk = { chat: s.data };
        } else {
          window.ChannelIO("openChat", this.orderData.channelTalk.chat.id);
        }

        this.orderData.createdAt = new Date();
        await this.saveOrder();
        this.$toasted.global.success(
          "주문서 접수가 완료되었습니다! 마이페이지로 이동합니다."
        );
        this.$router.push("/mypage");
      } else {
        this.$toasted.global.notice(
          "회원 가입 후 동일하게 접수 해주시면 됩니다!"
        );
      }
      this.btnLoading = false;
    },
  },
};
</script>

<style></style>
